/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint no-use-before-define: 0 */
// --> OFF */
/* eslint-disable */
import {enableProdMode} from '@angular/core';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
// declare var require: any;
declare let window: any;

Sentry.init({
  dsn: "https://cc638c6d84b44b9b80a5320a21c63828@o4504281150455808.ingest.sentry.io/4504290036285440",
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  attachStacktrace: true,
  release: "1.5.0.6.5",
  debug: false,
  environment: "release", //  development // release
});

let settings: any = {foo: 'bar'};

function configListener() {
  try {
    const configuration = JSON.parse(this.responseText);
    settings = configuration;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    settings.server.secretKey ? delete settings.server.secretKey : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    settings.server.iv ? delete settings.server.iv : null;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    settings.server.db_name ? delete settings.server.db_name : null;
    window.settings = settings;
    // console.log('hier testweise auskommentiert');
    platformBrowserDynamic([{provide: 'settings', useValue: configuration}]).bootstrapModule(AppModule)
      .then(ref => {
      }).catch(err => console.error(err));

  } catch (error) {
    console.error(error);
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function configFailed(_evt: any) {
  console.error('Error: retrieving config.json');
}

let path = `../assets/settings/settings.json`;
if (environment.production) {
  enableProdMode();
  path = `../../assets/settings/settings.json`;
} else {
  // path = `../../../settings.json`
}
console.log("environment ", environment);
const request = new XMLHttpRequest();
request.addEventListener('load', configListener);
request.addEventListener('error', configFailed);
request.open('GET', '../assets/settings/settings.json');
request.send();
